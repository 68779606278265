<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center" style="background-color:white; min-height: 100%;height: 100%;">
    <div class="container" style="pt-0; padding-top: 25px;">  
      <h1>Términos de uso</h1>
    <p> Última actualización: 29 de noviembre de 2021.</p>
    <p>Estos términos de uso están vigentes para todos los visitantes y/o usuarios y cuentas ya existentes así como las creadas a partir de la fecha de esta versión.<br>
      Por favor antes de usar Weekflash y cualquiera de nuestros servicios Weekflash relacionados lea este documento cuidadosamente, el cual determina su relación con Weekflash. <br>
      Estos términos se aplican tanto al sitio web www.weekflash.com como a todas las aplicaciones y servicios disponibles via el sitio web o de cualquier manera provistos por Weekflash a cualquier persona o entidad que entre o utilice el sitio y los servicios.<br>
      Estos términos y condiciones, junto con cualquier y todos los otros términos, condiciones, reglas, políticas o procedimientos publicados en el Sitio de vez en cuando y relacionados con el uso del Sitio o de los Servicios por el Usuario (incluyendo, sin limitación, todos los términos adjuntos y específicos de país), todos juntos y cada uno, los cuales pueden ser actualizados en cualquier momento por Weekflash, constituyen un Contrato legal vinculante entre Weekflash y el Usuario.<br>
      Haciendo clic en &quot;Acepto&quot; (o en cualquier otro botón que indica su aceptación de este Contrato y/o suscripción/registro a cualquiera de nuestros servicios) significa que Ud. acepta y se compromete al presente Contrato. Si no está de acuerdo con cualquiera de los términos de este Contrato, no haga clic en ningún botón o de ninguna manera entre, o use este Sitio o los Servicios, o ninguna información contenida en el Sitio.<br>
      Weekflash puede hacer cambios en los materiales y servicios ofrecidos en este sitio web y sus aplicaciones relacionadas en cualquier momento. Weekflash puede cambiar los términos de este contrato incluyendo cualquiera de las condiciones adicionales en cualquier momento. Si Weekflash cambia los términos y condiciones de uso, lo publicará en el sitio web www.weekflash.com.<br>
      Si cualquiera de las modificaciones es inaceptable, Ud. dejará absolutamente de utilizar este Sitio y los Servicios y terminar su registración y/o suscripción (si es que existe). Si usted no deja de utilizar este Sitio y los Servicios, entonces se considerará inequívocamente que Ud. ha aceptado el cambio. Es responsabilidad del usuario mantener la última versión de WeekFlash actualizada en su dispositivo para mayor seguridad.</p>
    <p>1. Su Estado Legal<br>
      Cada Usuario por la presente garantiza que si es una empresa u otra entidad legal, el Usuario está válidamente formado, existe bajo las leyes de su jurisdicción y ha autorizado debidamente a su agente/s para entrar en este Contrato. Si el Usuario es un individuo, el Usuario por la presente garantiza que es mayor de edad en su lugar de residencia.</p>
    <p>2. Registro/inscripción/suscripción<br>
      Si el Usuario se ha registrado, inscrito o suscrito para utilizar los Servicios, el Usuario por la presente garantiza que la información proporcionada para los efectos de dicho registro es completa y exacta. El Usuario se asegurará de que dicha información se mantenga correcta en todo momento. Al registrarse, cada Usuario recibirá una contraseña y una designación de cuenta. El Usuario reconoce y acepta que el Usuario, y no Weekflash, es responsable de todas y cada una de las actividades que se produzcan o conduzcan en relación con el uso de esa cuenta, sin importar si el Usuario autorizo o no, tales actividades.</p>
    <p>3. Materiales de Weekflash<br>
      Cierto contenido e información proporcionada en y a través de este Sitio y los Servicios, y que incluyen sin limitación, documentos, gráficos e imágenes que no son Contenido del Usuario (definido a continuación) (colectivamente, &quot;Materiales de Weekflash&quot;) se le proporcionan a Ud. gracias a Weekflash y a sus colaboradores y están protegidos por leyes de derecho de autor (Copyright) o marcas registradas. Weekflash le otorga una licencia limitada, personal, no exclusiva y no transferible para utilizar y mostrar los materiales de Weekflash exclusivamente para su uso personal en conexión con el Sitio y los Servicios.<br>
      Excepto que, y según lo expresamente permitido en el presente Contrato, Ud. no tiene derecho a modificar, editar, copiar, reproducir, crear trabajos derivados de, aplicar ingeniería inversa, modificar, mejorar o de cualquier modo, explotar cualquiera de los Materiales de Weekflash de cualquier manera. Esta licencia limitada termina automáticamente y sin previo aviso si Ud. no cumple con cualquier término de este Contrato. Exceptuando los derechos limitados concedidos en virtud del presente, Ud. reconoce que no tiene ningún derecho, título o interés, en cualquiera de los Materiales de Weekflash.</p>
    <p>4. Contenido de Usuario<br>
      4.1. El Usuario reconoce y acepta que:</p>
    <p>(a) toda la información, datos, texto, software, música, sonido, fotografías, imágenes, vídeo, comentarios, mensajes u otros materiales comunicados, presentados, o transmitidos por el Usuario y/o los invitados, participantes, no participantes, a través del Sitio o los Servicios (colectivamente, &quot;Contenido de Usuario&quot;), ya sea públicamente o transmitidos privadamente, son responsabilidad exclusiva de la persona/usuario que originó tal Contenido de Usuario,<br>
      (b) el Usuario o sus invitados, y no Weekflash, es/son responsable/s de todo Contenido de Usuario que sea subido, procesado, enviado electrónicamente, distribuido, comunicado, o de cualquier manera hecho disponible a través de la cuenta del Usuario (si el Usuario tiene alguna) sin importar que el Usuario lo haya o no autorizado,<br>
      (c) mediante el uso del Sitio y de los Servicios, el Usuario puede estar expuesto a Contenido de Usuario que sea ilegal, dañino, amenazante, abusivo, hostigador, agraviante, difamatorio, calumnioso, vulgar, obsceno, ofensivo, indecente, invasivo de la privacidad, odioso, o racial, étnica o de otro modo censurable.<br>
      (d) por comunicar o transmitir cualquier Contenido de Usuario utilizando el Sitio o los Servicios, el Usuario le otorga a Weekflash una licencia irrevocable, no exclusiva, libre de regalías y en todo el mundo con el propósito de usar tal Contenido de Usuario en la operación del Sitio para proporcionar los Servicios y le garantiza a Weekflash que tiene el derecho de otorgar dicha licencia para tales fines.<br>
      (e) El usuario que publique cualquier contenido, información o publicidad debe cumplir con todas las leyes y normas de cada país y/o región en la que está promocionando dicha información y/o publicidad.</p>
    <p>4.2. El Usuario además reconoce y acepta que Weekflash no controla el Contenido de Usuario originado por el Usuario, sus invitados u otros Usuarios del Sitio o de los Servicios y no garantiza la exactitud, integridad o calidad de tal Contenido de Usuario. No obstante, Weekflash puede, pero no está obligado a revisar todo el Contenido de Usuario y bloquear, modificar, cancelar el acceso a, o eliminar cualquier Contenido de Usuario que Weekflash, a su sola discreción, considere que no cumple con alguno de los requisitos del presente Contrato.<br>
      4.3. Weekflash se reserva el derecho de eliminar el Contenido de Usuario de sus bases de datos en cualquier momento y de vez en cuando sin previo aviso. Weekflash no será responsable por ninguna limpieza, eliminación, o falla para conservar cualquier Contenido de Usuario.<br>
      4.4. En caso de que el Usuario no cumpla con alguna de las cláusulas de este Acuerdo, Weekflash puede suspender o deshabilitar la cuenta del usuario y el acceso del usuario para utilizar el sitio o los servicios, y puede recuperar del Usuario, cualquier pérdida, daño, costo o gastos en que Weekflash haya incurrido debido a dicho incumplimiento.</p>
    <p>5. Conducta del Usuario<br>
      En conexión con el uso del Sitio y los Servicios y sin limitar ninguna otra obligación del Usuario bajo este Contrato o ley aplicable, el Usuario:<br>
      (a) cumplirá con: (1) este Contrato, incluidas las reglas de bloqueo de correo publicitario o comercial de Weekflash y todas las demás políticas publicadas en el Sitio de vez en cuando, (2) todas las leyes locales e internacionales aplicables al Usuario, que pueden incluir la Ley de Luxemburgo sobre la protección de datos en el sector de las comunicaciones electrónicas y todas las demás leyes relacionadas con mensajes de correo electrónico comercial no solicitado, difamación, privacidad, obscenidad, propiedad intelectual, protección de datos y protección de los registros de direcciones electrónicas del niño, (3) todas las demás normas o reglamentaciones aplicables al Usuario, incluyendo, entre otras cosas, reglamentos de valores, y (4) todas las pólizas de privacidad, políticas similares, o procedimientos a los que el Usuario pueda estar vinculado que están relacionados con el uso de Servicios por el Usuario;<br>
      (b) no subirá, publicará, enviará por correo electrónico, distribuirá, comunicará, transmitirá o lo contrario hará disponible cualquier Contenido de Usuario: (1) que sea ilegal, dañino, agraviante, amenazante, abusivo, hostigador, difamatorio, calumnioso, vulgar, obsceno, ofensivo, indecente, invasivo de la privacidad, odioso, o racial, étnico o de otro modo censurable, (2) infringirá cualquier patente, marca registrada, secreto comercial, derecho de autor u otro derecho de propiedad intelectual de cualquier otra parte, (3) que el Usuario no tiene derecho de hacer disponible por cualquier ley o de la relación contractual o fiduciaria (incluyendo información interna, propietaria, y confidencial o información obtenida o divulgada en conexión con una relación de empleo o conforme a un contrato de confidencialidad), o (4) que comprende o incluye cualquier &quot;correo propaganda basura&quot;, &quot;correo comercial basura&quot;, &quot;cartas de cadena&quot;, &quot;pirámides&quot; “spam” o cualquier forma similar de solicitación. <br>
      (c) no deberá utilizar el Sitio o los Servicios para enviar invitaciones, mensajes u otros materiales a individuos que no sean mayores de edad en su lugar de residencia (&quot;Menores&quot;), a menos que el Usuario este permitido para hacerlo bajo todas las leyes aplicables, o para perjudicar a Menores de cualquier manera y no enviará invitaciones, mensajes u otros materiales a Menores que comprometan a Weekflash con cualquier ley local o internacional, regla o reglamento que gobierna la privacidad de los niños, derechos de la personalidad o de cualquier manera relacionadas con la protección de Menores;<br>
      (d) no suplantará a cualquier otra persona o entidad, incluyendo Weekflash, o algún oficial de Weekflash, líder de foro, guía, o anfitrión, o declarara falsamente o tergiversara la afiliación del Usuario con cualquier otra persona o entidad;<br>
      (e) no interferirá con o interrumpirá el Sitio, o los Servicios, o servidores, o redes conectadas con el Sitio o los Servicios, o desobedecerá cualquier requisito, procedimiento, política o regulación de las redes conectadas con el Sitio o los Servicios;<br>
      (f) no participará en uso excesivo del Sitio o de los Servicios, según determinado por Weekflash a su entera discreción, incluyendo uso que afecte negativamente la velocidad, capacidad de respuesta, o funcionalidad del Sitio, o que interrumpa la disponibilidad del Sitio y de los Servicios de otros Usuarios;<br>
      (g) no intentará dañar, denegar servicio a, invadir, romper, invertirá la ingeniería o de cualquier manera interferirá con el Sitio o los Servicios (excepto en la medida en que la prohibición anterior no esté permitida por cualquier ley aplicable, incluyendo, sin limitación, como está establecido en los términos adjuntos con respecto a los Usuarios en Francia);<br>
      (h) no cargará, publicará, enviará por correo electrónico, distribuirá, comunicará, transmitirá, o lo contrario hará disponible cualquier virus o software malicioso que pueda dañar el funcionamiento de un equipo, el Sitio o los Servicios.</p>
    <p>El Usuario además reconoce y acepta que Weekflash puede cooperar con cualquier autoridad gubernamental en relación con cualquier investigación de uso del Sitio o los Servicios por el Usuario, incluyendo el uso en contravención de las leyes aplicables y podrá, de conformidad con las leyes aplicables, revelar a dicha autoridad gubernamental cualquier Contenido de Usuario y cualquier otra información del Usuario o uso del Sitio o los Servicios, en relación con esa investigación.</p>
    <p>6. Indemnización por el Usuario<br>
      El Usuario indemnizará, defenderá y exonerará a Weekflash de toda culpa culpa, así como a las afiliadas de Weekflash, y sus respectivos directores, oficiales, empleados, funcionarios y agentes, de y contra todas las reclamaciones, demandas, daños, responsabilidades y gastos (incluyendo indemnización total y completa por honorarios legales) que surja de o en relación con:<br>
      (a) uso del Usuario del Sitio o de los Servicios, incluyendo uso de o dependencia en cualquier información o material obtenido mediante el uso del Sitio o los Servicios,<br>
      (b) cualquier otro uso de los Servicios por terceros utilizando la cuenta del Usuario (ya sea o no autorizado por el Usuario),<br>
      (c) incumplimiento de este Contrato por el Usuario, incluyendo incumplimiento de cualquier representación y garantía del Usuario bajo este Contrato, o<br>
      (d) incumplimiento de cualquier ley aplicable al Usuario.</p>
    <p>7. Cancelación del Contrato<br>
      7.1. Este Contrato entrara en vigencia automáticamente con el primer uso del Sitio o de los Servicios por Usuario y con la aceptación de este Acuerdo y continuará hasta que sea terminado.<br>
      7.2. Weekflash puede cancelar la Suscripción del Usuario (si el Usuario tiene una suscripción) y automática e inmediatamente rescindir el presente Contrato mediante una notificación en caso de incumplimiento de cualquiera de las obligaciones, garantías o representaciones del Usuario bajo este Contrato (incluyendo cualquiera de las obligaciones del Usuario en virtud de la Clausula 4 (Contenido de Usuario)) o infringimiento de cualquier ley aplicable.<br>
      7.3. El Usuario puede cancelar la Suscripción del Usuario (si el Usuario tiene una suscripción) y rescindir el presente Contrato mediante aviso por escrito a Weekflash a través de la misma App en la pantalla Ajustes - Contáctanos, ó a través de nuestro sitio web en la página http://weekflash.com/contact.html .<br>
      7.4. Tras la cancelación de este Contrato por cualquier motivo, el Usuario inmediatamente detendrá el uso del Sitio y de los Servicios, y el Usuario reconoce y acepta que Weekflash puede, a su entera discreción, tomar cualquier medida que Weekflash considere razonable, necesaria, o deseable para evitar uso del Sitio o los Servicios por el Usuario, incluyendo bloqueo de la dirección IP. El Usuario además reconoce y acepta que tras la cancelación de este Contrato, Weekflash no estará obligado a mantener cualquier Contenido de Usuario o a proporcionar la misma al Usuario, pero puede elegir hacerlo a su entera discreción.<br>
      7.5. Cancelación de este Contrato por incumplimiento, tal como se define en la Cláusula 7.2 (Cancelación del Contrato) no le da derecho de reembolso al Usuario de cualquier pago de Suscripción previamente pagado por el Usuario.<br>
      7.6. Las cláusulas a continuación sobrevivirán la cancelación de este Contrato por cualquier razón:<br>
      (a) todas las representaciones, garantías, e indemnizaciones del Usuario dadas bajo este contrato;<br>
      (b) todas las renuncias de garantías, limitaciones, y exclusiones de responsabilidad.</p>
    <p>8. Suministro del Sitio y de los Servicios<br>
      8.1. Weekflash se reserva el derecho de en cualquier momento y de vez en cuando modificar, suspender, o interrumpir, temporal o permanentemente, los Servicios o cualquier parte de los mismos, o el acceso del Usuario al mismo, y de modificar, suspender o eliminar el Sitio, las Aplicaciones o cualquier parte de los mismos y Weekflash usará esfuerzos comercialmente razonables para avisar con razonable anticipación de los cambios que afecten los Servicios de una manera negativa. En el caso de los cambios que afecten los Servicios significativamente de una manera negativa, el Usuario tiene derecho a cancelar la Suscripción de Usuario, si hay alguna. El Usuario reconoce y acepta que Weekflash no será responsable ante al Usuario o a terceros, por cualquier modificación, suspensión, o interrupción de los Servicios de cualquier modificación, suspensión o eliminación del Sitio.<br>
      8.2. EL USUARIO RECONOCE Y ACEPTA QUE EL SITIO Y LOS SERVICIOS SE PROPORCIONAN &quot;TAL CUAL SON”, &quot;DONDE ESTAN,&quot; &quot;COMO ESTÁN DISPONIBLES&quot; Y &quot;CON TODAS LAS FALLAS,&quot; Y QUE, CON EXCEPCION A COMO PUEDA SER DE OTRA MANERA EN ESTE CONTRATO CON RESPECTO A USUARIOS EN PAISES ESPECIFICOS, WEEKFLASH NO TIENE RESPONSABILIDAD O RESPONSABILIDAD DERIVADA POR LA PERDIDA O ELIMINACION DE, O FALLA PARA RECIBIR, PROCESAR O ALMACENAR CUALQUIER CONTENIDO DE USUARIO MANTENIDOS O TRANSMITIDOS MEDIANTE USO DE LOS SERVICIOS.<br>
      8.3. EL USUARIO RECONOCE Y ACEPTA QUE LA INFORMACIÓN, SOFTWARE, PRODUCTOS Y SERVICIOS CONTENIDOS EN O DISPONIBLES EN EL SITIO O LOS SERVICIOS, INCLUYENDO LA INFORMACIÓN, SOFTWARE, PRODUCTOS Y SERVICIOS, PUESTOS A DISPOSICIÓN POR OTROS USUARIOS DEL SITIO O LOS SERVICIOS, PUEDE INCLUIR INEXACTITUDES O ERRORES.<br>
      8.4. WEEKFLASH NO HACE REPRESENTACIONES NI GARANTIAS CON RESPECTO A LO APROPIADO, FIABILIDAD, DISPONIBILIDAD, OPORTUNIDAD, CALIDAD O AUSENCIA DE VIRUSES, O DE OTROS COMPONENTES DAÑINOS DEL SITIO O LOS SERVICIOS, O LA EXACTITUD DE DICHA INFORMACIÓN, SOFTWARE, PRODUCTOS Y SERVICIOS.<br>
      8.5. EN LA MEDIDA PERMITIDA POR LA LEY APLICABLE, WEEKFLASH, SUS AFILIADOS, Y SUS RESPECTIVOS DIRECTORES, OFICIALES, EMPLEADOS, FUNCIONARIOS Y AGENTES RENUNCIAN TODAS LAS GARANTIAS, EXPRESAS O IMPLICITAS, CON RESPECTO A TAL INFORMACION, SOFTWARE, PRODUCTOS O SERVICIOS, INCLUYENDO CUALQUIER GARANTIA DE NO INFRACCIÓN DE LOS DERECHOS DE TERCEROS, Y LAS GARANTIAS IMPLICITAS DE COMERCIABILIDAD Y CAPACIDAD DE PROPOSITO.<br>
    8.6 SOBRE LA INFORMACIÓN CONTENIDA EN WEEKFLASH (INCLUYE APLICACIONES, SITIOS WEB Y OTROS MEDIOS DIGITALES O IMPRESOS PUBLICADOS DIRECTA O INDIRECTAMENTE POR WEEKFLASH): EL USUARIO ACEPTA QUE NINGUNA INFORMACIÓN CONTENIDA EN WEEKFLASH DEBE SER TOMADA COMO FIDEDIGNA, INCLUYENDO CUALQUIER TIPO DE ACTIVIDADES Y/O EVENTOS ANUNCIADOS. ES RESPONSABILIDAD DEL USUARIO VERIFICAR LA VERACIDAD DE ABSOLUTAMENTE TODA LA INFORMACIÓN AL RESPECTO. WEEKFLASH NO SE HACE RESPONSABLE DE NINGUNA CONTINGENCIA O SITUACIÓN RESULTANTE DE LA NO VERACIDAD DE LA INFORMACIÓN DE ACTIVIDADES Y/O EVENTOS CONTENIDA EN EL PRODUCTO.<br>
    8.7 ACTIVIDADES Y/O EVENTOS: EL USUARIO ACEPTA QUE WEEFLASH PUEDE ANUNCIAR ACTIVIDADES Y/O EVENTOS PÚBLICOS O PRIVADOS, SIN EMBARGO WEEKFLASH NO SE RESPONSABILIZA POR LA ORGANIZACIÓN O CUALQUIER DAÑO O PERJUICIO QUE PUEDA RESULTAR POR LA ASISTENCIA O INASISTENCIA A CUALQUIERA DE LAS ACTIVIDADES Y/O EVENTOS. LAS ACTIVIDADES Y/O EVENTOS QUE FIGURAN EN WEEKFLASH SON DE PLENA RESPONSABILIDAD DE LOS ORGANIZADORES O RESPONSABLES DE LOS MISMOS.<br>
    8.8 ES POSIBLE QUE ANUNCIEMOS ACTIVIDADES Y/O EVENTOS DE CONOCIMIENTO PÚBLICO CON EL FIN DE AYUDAR A LA PROMOCIÓN DE LOS MISMOS. SI LA INFORMACIÓN QUE ANUNCIAMOS ES ERRÓNEA O EL ORGANIZADOR/RESPONSABLE DE LA ACTIVIDAD Y/O EVENTO VE QUE NO ES CONVENIENTE QUE SU ACTIVIDAD Y/O EVENTO SEA PUBLICADO EN WEEKFLASH Y DESEA CAMBIARLO O ELIMINARLO, NOS PUEDE INFORMAR MEDIANTE MENSAJE ESCRITO A TRAVÉS DEL SITIO WEB WWW.WEEKFLASH.COM/CONTACT Ó A TRAVÉS DE LA INFORMACIÓN DE CONTACTO PUBLICADA EN NUESTROS PRODUCTOS. UNA VEZ RECIBIDO Y LEÍDO, PROCEDEREMOS A MODIFICARLO O BORRARLO SEGÚN LA SOLICITUD DEL RESPONSABLE ORGANIZADOR, DESPUÉS DE UNOS DÍAS DE PLAZO PARA LA OPERACIÓN. NO NOS HACEMOS RESPONSABLES POR NINGUNA INFORMACIÓN ERRONEA QUE PUEDAN TENER LAS ACTIVIDADES Y/O EVENTOS, TANTO CON LOS USUARIOS COMO CON LOS ORGANIZADORES.<br>
    8.9 SI UN USUARIO PUBLICA ALGUNA ACTIVIDAD Y/O EVENTO, PRIVADO O PÚBLICO, WEEKFLASH SE RESERVA EL DERECHO DE LA PUBLICACIÓN Y/O DESPUBLICACIÓN O BORRADO DEL MISMO. ADEMÁS EL USUARIO DESLIGA A WEEKFLASH DE TODA RESPONSABILIDAD QUE DERIVE DE LA PUBLICACIÓN O REALIZACIÓN DE LA ACTIVIDAD Y/O EVENTO ANTES, DURANTE Y DESPUÉS DEL MISMO. LA RESPONSABILIDAD PLENA LA ASIMIRÁN EL USUARIO Y/O LOS ORGANIZADORES DEL EVENTO, SEGÚN CORRESPONDA LEGALMENTE.</p>
    <p>9. LIMITACIONES Y EXCLUSIONES DE RESPONSABILIDAD DE WEEKFLASH<br>
      9.1. EN NINGUN CASO WEEKFLASH, SUS AFILIADOS, O SUS RESPECTIVOS DIRECTORES, FUNCIONARIOS, EMPLEADOS, FUNCIONARIOS O AGENTES SERAN RESPONSABLES POR CUALQUIER DAÑO DE CUALQUIER TIPO YA SEA DIRECTO, INDIRECTO, PUNITIVO, INCIDENTAL, ESPECIAL O DAÑOS CONSECUENTES DE NINGUN TIPO, INCLUYENDO CUALQUIER CONTENDO AMENAZANTE, DIFAMATORIO, OBSCENO, AGRAVIANTE O CONTENIDO O CONDUCTA ILEGAL DE CUALQUIER PARTE, O CUALQUIER INFRACCION DE LOS DERECHOS DE TERCEROS, INCLUYENDO DERECHOS DE PROPIEDAD INTELECTUAL, O CUALQUIER CONTENIDO ENVIADO USANDO, O INCLUIDO EN, EL SITIO APLICACIONES O LOS SERVICIOS POR CUALQUIER TERCERO.<br>
      SI EL USUARIO ESTA INSATISFECHO CON CUALQUIER PARTE DEL SITIO O LOS SERVICIOS, EL UNICO Y EXCLUSIVO RECURSO DEL USUARIO ES CANCELAR ESTE CONTRATO Y DESCONTINUAR EL USO DEL SITIO Y LOS SERVICIOS.<br>
      9.2. EN CASO DE QUE, SIN IMPORTAR LAS EXCLUSIONES ANTERIORES DE RESPONSABILIDAD, WEEKFLASH, CUALQUIERA DE SUS AFILIADOS O CUALQUIERA DE SUS RESPECTIVOS DIRECTORES, FUNCIONARIOS, EMPLEADOS, FUNCIONARIOS O AGENTES ES RESPONSABLE AL USUARIO POR CUALQUIER CANTIDAD BAJO ESTE CONTRATO, BAJO CUALQUIER TEORIA DE LA RECUPERACION, YA SEA BASADO EN CONTRATO, AGRAVIO, ESTRICTA RESPONSABILIDAD O DE CUALQUIER MANERA, LA RESPONSABILIDAD TOTAL DE WEEKFLASH (O DE SUS AFILIADOS, DIRECTORES, OFICIALES, EMPLEADOS, O AGENTES, SEGUN CORRESPONDA) CON RESPECTO A DICHAS CANTIDADES NO EXCEDERA LA MAYOR DE:<br>
      (a) LA CANTIDAD DE CIEN DÓLARES AMERICANOS (100 USD), Y<br>
      (b) LAS CANTIDADES RECIBIDAS REALMENTE POR WEEKFLASH DEL USUARIO BAJO ESTE CONTRATO.<br>
      9.3. EL USUARIO RECONOCE Y ACEPTA QUE SIN LAS ANTERIORES EXCLUSIONES Y LIMITACIONES DE RESPONSABILIDAD, WEEKFLASH NO SERIA CAPAZ DE OFRECER EL SITIO O LOS SERVICIOS, Y QUE TALES EXCLUSIONES Y LIMITACIONES DE RESPONSABILIDAD APLICARAN, INCLUSO SI PROMOVIERAN A FALLAR LOS REMEDIOS PARA EL USUARIO EN VIRTUD DE ESTE CONTRATO. No obstante lo anterior, algunas jurisdicciones no permiten la exclusión o limitación de responsabilidad por daños incidentales o consecuentes, daños que surjan del delito, daños a la vida, cuerpo o salud o en caso de lesiones corporales, muerte, fraude, negligencia, mala conducta deliberada o incumplimiento de una obligación fundamental, y por consiguiente las limitaciones y exclusiones anteriores no aplican al Usuario en esa medida (incluyendo, sin limitación, como es establecido con respecto a los Usuarios en Alemania en los términos adjuntos de países específicos).</p>
    <p>10. Derechos de Propiedad Intelectual<br>
      El Sitio y los Servicios y toda la información y las pantallas que aparecen en este Sitio, incluyendo documentos, servicios, diseño del sitio, texto, gráficos, logotipos, imágenes e iconos, así como posición de los mismos, son propiedad exclusiva de Weekflash.com, sus afiliados, o licencia por terceros a Weekflash.com. Exceptuando como sea requerido o limitado por leyes aplicables, cualquier reproducción, distribución, modificación, retransmisión o publicación de cualquier material con derechos de autor está estrictamente prohibido sin el consentimiento expreso y por escrito del propietario de tales derechos de autor o de licencia. Weekflash se reserva todos los derechos en el Sitio y los Servicios que no se conceden expresamente. Las marcas registradas, nombres y logotipos en el Sitio son propiedad de sus respectivos propietarios. Nada en el presente Contrato se considerará asignamiento o transferencia de ningún derecho de cualquier propiedad intelectual al Usuario. El Usuario además reconoce y acuerda que los Materiales de Weekflash y otro contenido a disposición del Usuario a través de los Servicios pueden ser propiedad intelectual de terceros.</p>
    <p>11. Política de Privacidad<br>
      El uso de este Sitio y sus aplicaciones se rige por la política de privacidad de Weekflash que está disponible en nuestro sitio web.<br>
      12. General<br>
      12.1. Weekflash puede proporcionar referencias, marcos o hipervínculos a sitios Web mantenidos por terceros en la Internet. Weekflash no garantiza que ha examinado dichos Sitios Web de terceros y no hace afirmaciones, representaciones o da garantías con respecto a dichos Sitios Web de terceros o el contenido de los mismos. Weekflash no se hace responsable, ni endorsa o recomienda, productos o servicios prestados por terceros a través de dichos Sitios Web de terceros o por cualquier otro medio.<br>
      12.2. El Usuario reconoce y acepta que el Usuario y no Weekflash, es responsable por la determinación de cuales leyes pueden aplicar al uso, por el Usuario, del Sitio y los Servicios y también de la evaluación de las obligaciones del Usuario en virtud de dichas leyes.<br>
      12.3. Todos los avisos y otras comunicaciones que sean requeridos o permitidos por Weekflash para el Usuario en virtud de este Contrato se considerarán correctamente dados en la fecha de:<br>
      (a) publicación en el Sitio, y/o<br>
      (b) envío por correo electrónico a la última dirección de correo electrónico compilada por Weekflash.</p>
    <p>12.4. Este Contrato contiene la totalidad del entendimiento entre Weekflash y el Usuario relacionadas con el Sitio y los Servicios. Tanto el Usuario como Weekflash confirman que no han confiado en, y no dispondrán de, ningún remedio por cualquier acuerdo, garantía, declaración, representación, o entendimiento por cualquiera de las partes (sean o no parte interesada de este Contrato), a menos que ese acuerdo, garantía, declaración, representación, o entendimiento estén establecidos expresamente en este Contrato.<br>
      12.5. El uso de los términos &quot;incluye&quot; e &quot;incluyendo&quot; y términos similares, no se entenderán como límites de no inclusión de otros aspectos que podrían ser incluidos en su uso.<br>
      12.6. Este Contrato se regirá por las leyes de Luxemburgo. Weekflash y el Usuario acuerdan someterse a la jurisdicción exclusiva de los tribunales de la ciudad de Luxemburgo, el Gran Ducado de Luxemburgo en relación con cualquier reclamo que surja bajo este o en conexión con este Contrato, que no se pueda resolver mediante un procedimiento de conciliación de mutuo acuerdo. El Usuario no puede asignar este Contrato sin el previo consentimiento escrito de Weekflash, el cual puede ser retenido a la sola discreción de Weekflash.<br>
      12.7. Weekflash puede asignar este Contrato en cualquier momento al sucesor en interés por una fusión, consolidación, u otra reorganización corporativa en la que Weekflash participe, o al comprador de todos o substancialmente todos los valores de Weekflash relacionados con el Sitio.<br>
      12.8. En cualquier disputa que se derive de este Contrato, la parte que substancialmente prevalga tendrá derecho a pagos razonables de sus honorarios legales y costos.<br>
      12.9. La falla de cualquiera de las partes por enforzar o hacer cumplir cualquier derecho o disposición de este Contrato no constituirá una renuncia de dicha disposición o de cualquier otra disposición de este Contrato.<br>
      12.10. En caso de que cualquier disposición de este Contrato sea determinada inválida o inejecutable por un tribunal, dicha disposición se considerará separable y el resto de este Contrato continuará en plena vigencia y efecto.<br>
    </p>
    </div>

  </section><!-- End Hero -->


</template>

<script>

import router from "../router"

export default {
    
}
</script>

<style>

</style>