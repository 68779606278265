<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center" style="background-color:white; min-height: 100%;height: 100%;">
    <div class="container" style="pt-0; padding-top: 25px;">  
         <h1>Privacy Policy</h1>
<p>Last updated: September 29, 2021</p>
<p> This privacy policy explains how WeekFlash handles  your personal information and data. </p>
<p> This  privacy policy applies to all the products, services, Apps and websites offered  by WeekFlash and their affiliates, except where otherwise noted. We refer to  those products, services and websites collectively as the &ldquo;services&rdquo; in this  policy. Unless otherwise noted, our services are provided by WeekFlash.</p>
<p> <strong>Questions?</strong> For questions  regarding our privacy policy or practices, contact WeekFlash by email by  filling the form on the following web address: www.weekflash.com/contact.html</p>
<p> THE  INFORMATION YOU PUBLISH OR COMMUNICATE:</p>
<ul type="disc">
  <li><strong>The data       you publish is owned by you.</strong> Not only that, but WeekFlash       treats your posts as if they were private. We don't sell them to anyone.</li>
</ul>
<p>WHAT  INFORMATION DOES WEEKFLASH COLLECT?</p>
<p> When you use WeekFlash, we collect information  relating to you and your use of our services from a variety of sources. These  are listed below. The sections afterward describe what we do with this  information.</p>
<p> <strong>Information we collect directly from you</strong></p>
<ol start="1" type="1">
  <ul type="circle">
    <li><strong>Registration        information.</strong> You need a WeekFlash account before you can create contents on WeekFlash.        When you register for an account, we collect your username, password,        mobile number, age and email address. </li>
    <li><strong>Account        settings.</strong> You can set various preferences and personal details in the App. For        example, your complete name and profile picture.</li>
    <li><strong>Address        book/Contacts information.</strong> We allow you to import your        contacts phone numbers so you can easily find or invite your friends to        WeekFlash. We don't use these phone numbers for our own purposes.</li>
    <li><strong>Published data.</strong> We store        your published data for you.</li>
    <li><strong>Other data        you intentionally share.</strong> We may collect your personal        information or data if you submit it to us in other contexts. For        example, if you provide us with a testimonial, or participate in a WeekFlash        contest.</li>
  </ul>
</ol>
<p><strong>We safeguard your friends' phone numbers.</strong> Rest assured, WeekFlash  will not use your friend&rsquo;s phone numbers except at your direction. We  definitely don&rsquo;t sell those phone numbers to any third parties.</p>
<p> <strong>Information we collect about you from  other sources</strong></p>
<ol start="1" type="1">
  <ul type="circle">
    <li><strong>Usage        data.</strong> We collect usage data about you whenever you interact with our services.        This may include all interaction inside our app, what you click on, when        you performed those actions, and so on. Additionally, like most websites        and apps today, our web servers keep log files that record data each time        a device accesses those servers. The log files contain data about the        nature of each access, including originating IP addresses.</li>
    <li><strong>Device        data.</strong> We collect data from the device and application you use to access our        services, such as your IP address and browser type. We may also infer        your geographic location based on your IP address.</li>
    <li><strong>Referral        data.</strong> If you arrive at a WeekFlash website from an external source (such as a        link on another website or in an email), we record information about the        source that referred you to us.</li>
    <li><strong>Information        from third parties.</strong> We may collect your personal information or        data from third parties if you give permission to those third parties to        share your information with us. For example, you have the option of        registering and signing into WeekFlash with your Facebook account        details. If you do this, the authentication of your logon details is        handled by Facebook and we only collect information about your Facebook        account that you expressly agree to share with us at the time you give        permission for your WeekFlash account to be linked to your Facebook        account.</li>
    <li><strong>Information        from page tags.</strong> We use third party tracking services that employ cookies and tags to        collect aggregated and anonymized data about visitors to our websites.        This data includes usage and user statistics.</li>
  </ul>
</ol>
<p><strong>How does WeekFlash  use the information we collect? </strong></p>
<p><strong>We treat your posts, chats, images as  information that is private to you.</strong> We do not use your content data for our  own purposes, except in the limited circumstances described in this privacy  policy or unless we have your express consent. We do not sell your data to  third parties. </p>
<p> Generally, we use the information we collect from  you in connection with providing our services to you and, on your behalf, to  your friends. For example, specific ways we use this information are listed  below. (See the next section of this privacy policy to see who we share your  information with.)</p>
<ol start="1" type="1">
  <ul type="circle">
    <li><strong>To provide        you with our services.</strong></li>
    <ul type="square">
      <li>This         includes providing you with customer support, which requires us to         access your information to assist you (such as technical         troubleshooting).</li>
    </ul>
    <li><strong>To manage        our services.</strong> We internally use your information, including certain data, for the        following limited purposes: </li>
    <ul type="square">
      <li><strong>To         monitor and improve our services and features.</strong> We         internally perform statistical and other analysis on information we         collect (including usage data, device data, referral data) to analyze         and measure user behavior and trends, to understand how people use our         services, and to monitor, troubleshoot and improve our services. </li>
      <li><strong>To assist         the enforcement of our Terms of Use.</strong></li>
      <li><strong>To         prevent potentially illegal activities.</strong></li>
      <li><strong>To screen         for undesirable or abusive activity.</strong> For example, we have         automated systems that screen content for phishing activities, spam, and         fraud.</li>
    </ul>
    <li><strong>To create        new services, features or content (public data and metadata only).</strong> We may        use your statistical data, to create and provide new services, features        or content. When we do this, users will not be identified or identifiable        unless we have obtained their permission.</li>
    <li><strong>To contact        you about your service or account.</strong> We occasionally send you        communications of information about our Services.</li>
    <li><strong>To respond        to legal requests and prevent harm.</strong> If we receive a subpoena        or other legal request, we may need to inspect the data we hold to        determine how to respond.</li>
  </ul>
</ol>
<p><strong>With whom do we  share or disclose your information? </strong></p>
<p><strong>We don't sell your data!</strong></p>
<p><strong>When might we disclose your data to  third parties? </strong>Only  for a limited number of reasons. Mostly commonly, we share your information  with our service providers who help us to provide our services to you. We  contractually bind these service providers to keep your information confidential  and to use it only for the purpose of providing their services to us. By using  our services, you authorize WeekFlash to sub-contract in this manner on your  behalf. </p>
<p> Rare circumstances include when we need to share  information if required by law, or in a corporate restructuring or acquisition  context (see below for more details).</p>
<p> <strong>Sharing your data with the public.</strong> By default,  your content data is for you and your friends only. </p>
<p> We recognize that you have entrusted us with  safeguarding the privacy of your information. Because that trust is very  important to us, the only time we will disclose or share your personal  information or content with a third party is when we have done one of three  things, in accordance with applicable law: (a) given you notice, such as in  this privacy policy; (b) obtained your express consent, such as through an  opt-in checkbox; or (c) anonymized the information so that individuals cannot  be identified by it. Where required by law, we will obtain your express consent  prior to disclosing or sharing any personal information.</p>
<p> We may disclose:</p>
<ol start="1" type="1">
  <ul type="circle">
    <li><strong>Your        information to our service providers.</strong> We use service providers        who help us to provide you with our services. We give relevant persons        working for some of these providers access to your information, but only        to the extent necessary for them to perform their services for us. We        also implement reasonable contractual and technical protections to ensure        the confidentiality of your personal information and data is maintained,        used only for the provision of their services to us, and handled in        accordance with this privacy policy. Examples of service providers may        include payment processors, email service providers, and web traffic        analytics tools.</li>
    <li><strong>Aggregated        information to third parties to improve or promote our services.</strong> No        individuals can be identified or linked to any part of the information we        share with third parties to improve or promote our services.</li>
    <li><strong>The        presence of a cookie to advertise our services.</strong> We may        ask advertisers to display ads promoting our services on other websites.        We may ask them to deliver those ads based on the presence of a cookie        but in doing so will not share any other information with the advertiser.        Our advertising network partners may use cookies and page tags or web        beacons to collect certain non-personal information about your activities        on this and other websites to provide you with targeted advertising based        upon your interests. </li>
    <li><strong>Your        information if required or permitted by law.</strong> We may        disclose your information as required or permitted by law, or when we        believe that disclosure is necessary to protect our rights, and/or to        comply with a judicial proceeding, court order, subpoena, or other legal        process served on us.</li>
    <li><strong>Your        information if there's a change in business ownership or structure.</strong> If        ownership of all or substantially all of our business changes, or we        undertake a corporate reorganization (including a merger or consolidation)        or any other action or transfer between WeekFlash entities, you expressly        consent to WeekFlash transferring your information to the new owner or        successor entity so that we can continue providing our services. If        required, WeekFlash will notify the applicable data protection agency in        each jurisdiction of such a transfer in accordance with the notification        procedures under applicable data protection laws.</li>
    <li><strong>Information        you expressly consent to be shared.</strong> For example, we may        expressly request your permission to provide your contact details to        third parties for various purposes, including to allow those third        parties to contact you for marketing purposes. (You may later revoke your        permission, but if you wish to stop receiving communications from a third        party to which we provided your information with your permission, you        will need to contact that third party directly.)</li>
  </ul>
</ol>
<p><strong>What are your  rights to your information? </strong></p>
<p>  You can:</p>
<ol start="1" type="1">
  <ul type="circle">
    <li><strong>Update        your account details.</strong> You can update your registration and other        account information on the App. Information is updated immediately.</li>
    <li><strong>Delete        your content data.</strong> Deleting content data will not permanently        delete content data immediately. All the information will still be        available to you for one week after published in case you deleted        something by mistake, which you will be able to ask for it by contacting        us. To the extent permitted by law, we will permanently delete your data        if you request to cancel your account.</li>
    <li><strong>Cancel        your account.</strong> To cancel and delete your account, please contact us. Deleting your        account will cause all the content data in the account to be permanently        deleted, as permitted by law, and will disable your access to any other        services that require a WeekFlash account. We will respond to any such        request, and any appropriate request to access, correct, update or delete        your personal information within the time period specified by law (if        applicable) or without excessive delay. We will promptly fulfill requests        to delete personal data unless the request is not technically feasible or        such data is required to be retained by law (in which case we will block        access to such data, if required by law).</li>
  </ul>
</ol>
<p><strong>For how long do we retain your data?</strong> We generally  retain your content data for a week and your personal information for as long  as you have an account with us, or to comply with our legal obligations,  resolve disputes, or enforce our agreements.</p>
<p> <strong>Security,  cookies and other important information </strong></p>
<p><strong>Changes to this privacy policy.</strong> We may modify  this privacy policy at any time, but if we do so, we will notify you by  publishing the changes on this website / link. If we determine the changes are  material, we will provide you with additional, prominent notice as is  appropriate under the circumstances, such as via email. </p>
<p> For any changes to this privacy policy for which you  are required to provide prior consent, we will provide you with reasonable  notice of such changes before they become effective and provide you with the  opportunity to consent to those changes. If you do not cancel your subscription  and continue to use our services beyond the advance-notice period, you will be  considered as having expressly consented to the changes in our privacy policy.  If you disagree with the terms of this privacy policy or any updated privacy  policy, you may close your account at any time.</p>
<ol start="1" type="1">
  <ul type="circle">
    <li><strong>Security.</strong> We are        committed to handling your personal information and data with integrity        and care. However, regardless of the security protections and precautions        we undertake, there is always a risk that your personal data may be        viewed and used by unauthorized third parties as a result of collecting        and transmitting your data through the internet.</li>
    <li><strong>Cookies.</strong> We use        cookies on our websites. Cookies are small bits of data we store on the        device you use to access our services so we can recognize repeat users.        Each cookie expires after a certain period of time, depending on what we        use it for. We use cookies for several reasons: </li>
    <ul type="square">
      <li><strong>To make         our site easier to use.</strong> If you use the &quot;Remember         me&quot; feature when you sign into your account, we may store your         username in a cookie to make it quicker for you to sign in whenever you         return to WeekFlash.</li>
      <li><strong>For         security reasons.</strong> We use cookies to authenticate your         identity, such as confirming whether you are currently logged into WeekFlash.</li>
      <li><strong>To         provide you with personalized content.</strong> We may store user         preferences, such as your default language, in cookies to personalize         the content you see. We also use cookies to ensure that users can&rsquo;t         retake certain contents that they have already completed.</li>
      <li><strong>To         improve our services.</strong> We use cookies to measure your         usage of our websites and track referral data, as well as to         occasionally display different versions of content to you. This         information helps us to develop and improve our services and optimize         the content we display to users.</li>
    </ul>
  </ul>
</ol>
<p>By using our websites and agreeing to this privacy  policy, you expressly consent to the use of cookies as described in this  policy. </p>
<ol start="1" type="1">
  <ul type="circle">
    <li><strong>Safety of        Minors and COPPA.</strong> Our services are not intended for and may not        be used by minors. &quot;Minors&quot; are individuals under the age of        majority in their place of residence (or under 13 in the United States). WeekFlash        does not knowingly collect personal data from minors or allow them to        register. If it comes to our attention that we have collected personal        data from a minor, we may delete this information without notice. If you        have reason to believe that this has occurred, please contact us at http://weekflash.com/contact.html.</li>
    <li><strong>English        version controls.</strong> Non-English translations of this privacy        policy are provided for convenience. In the event of any ambiguity or        conflict between translations, the English version is authoritative.</li>
  </ul>
</ol>
<p><strong>Additional  information for European Union users </strong></p>
<ol start="1" type="1">
  <ul type="circle">
    <li><strong>&quot;Personal        data&quot;.</strong> For users located in the EU, references to &quot;personal        information&quot; in this policy are equivalent to what is commonly        referred to as &quot;personal data&quot; in the EU.</li>
    <li><strong>About IP        addresses.</strong> Our servers record the incoming IP addresses of visitors to our websites        (whether or not the visitor has a WeekFlash account) and store the IP        addresses in log files. We use these log files for purposes such as        system administration and maintenance, record keeping, tracking referring        web sites, inferring your location, and security purposes (e.g.        controlling abuse, spam and DDOS attacks). We also store IP addresses along        with certain actions you take on our system. IP addresses are only linked        to content responses if a content creator has configured a content to        collect IP addresses. By agreeing to this privacy policy, you expressly        consent to WeekFlash using your IP address for the foregoing purposes. If        you wish to opt out from the foregoing consent to use your IP address,        you must cancel your account (if you have one) or not respond to a content        if requested to do so.</li>
    <li><strong>Accessing        and correcting your personal data.</strong> You have the right to access and        correct the personal information that WeekFlash holds about you.</li>
  </ul>
</ol>
    </div>

  </section><!-- End Hero -->


</template>

<script>

import router from "../router"

export default {
    
}
</script>

<style>

</style>