<template>

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center" style="background-color:white; min-height: 100%;height: 100%;">
    <div class="container" style="pt-0; padding-top: 25px;">  
        <div class="row">
            <div class="col-12">
                <img :src="image" style="width:100%;height:375px;object-fit:cover" class="img-thumbnail" alt="...">
            </div>
        </div>

        <div class="row"> 
            <div class="col-12">
                <h1 class="pt-3 text-left text-truncate" style="color:#4a4a4a">{{name}}</h1>
                <h6 class="text-left text-truncate" style="color:#4a4a4a">{{location}}</h6>
            </div>
        </div>

        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-10">
                <a :href="link"><img src="/assets/img/planButton.png" class="img-fluid pt-3" alt="..."></a>
            </div>
            <div class="col-1">
            
            </div>
        </div>
    </div>

  </section><!-- End Hero -->


</template>

<script>


import router from "../router"


export default {
    setup () {
        
    },
    data() {
        return {
            name: "",
            image: window.location.origin + "/assets/img/defPlan.png",
            link: "",
            location:""
        }
    },
    async created(){
        console.log(this.$route.params.planId)
        const resPlanInfo = await fetch("https://weekflash-e92e4.firebaseio.com/plans/" + this.$route.params.planId + "/planInfo.json")
        const dataPlanInfo = await resPlanInfo.json()
        if (dataPlanInfo)
        {
            if (dataPlanInfo.hasOwnProperty("name"))
            {
                this.name = dataPlanInfo.name
            }
            
            if (dataPlanInfo.hasOwnProperty("location") && dataPlanInfo.location.hasOwnProperty("name"))
            {
                this.location = dataPlanInfo.location.name
            }
            
            if (dataPlanInfo.hasOwnProperty("dynamicLink"))
            {
                this.link = dataPlanInfo.dynamicLink
            }
        }

        const resPlanAlbum = await fetch("https://weekflash-e92e4.firebaseio.com/plans/" + this.$route.params.planId + "/album.json")
        const dataPlanAlbum = await resPlanAlbum.json()
        if (dataPlanAlbum)
        {
            for (const userIndex in dataPlanAlbum)
            {
                for (const photoIndex in dataPlanAlbum[userIndex])
                {
                    if(dataPlanAlbum[userIndex][photoIndex].includes(".mov"))
                    {
                        const pathFile = dataPlanAlbum[userIndex][photoIndex].split('file%2F');
                        if (pathFile.length > 1)
                        {
                            const noMov = pathFile[1].split('.mov');
                            this.image = pathFile[0] + "file%2Fthumbnail.jpg" + noMov[1];
                        }
                    }
                    else
                    {
                        const pathFile = dataPlanAlbum[userIndex][photoIndex].split('file%2F');
                        const pathAlbum = dataPlanAlbum[userIndex][photoIndex].split('album%2F');
                
                        if (pathFile.length > 1)
                        {
                            this.image = pathFile[0] + "file%2Fmedium___" + pathFile[1];
                        }
                        else if (pathAlbum.length > 1)
                        {
                            this.image = pathAlbum[0] + "album%2FplanMiddle___" + pathAlbum[1];
                        }
                    }
                    break
                }
            }
        }
        
        if (!dataPlanInfo && !dataPlanAlbum)
        {
            router.push("/")
        }
        
    }
}
</script>

<style>

</style>