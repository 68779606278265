<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center" style="background-color:white; min-height: 100%;height: 100%;">
    <div class="container" style="pt-0; padding-top: 25px;">  
       <h1>Política de Privacidad</h1>
<p>Última actualización: 27 de Enero de 2021</p>
<p>&nbsp;</p>
    <p>WeekFlash obtiene información a través de la información que usted introduce y los clicks / tabs que hace en nuestro sitio web, aplicaciones y servicios. El objetivo principal de obtener estos datos es entender y proporcionar al usuario una experiencia más.</p>
    <p>No compartiremos sus datos personales, email, u otra información, salvo con sus amigos de Weekflash. Los usuarios de Weekflash que no son sus amigos podrán ver una información básica de su perfil, la cual no incluye vuestro correo electrónico ni otra manera de contacto con la excepción del botón de agregar como amigo.<br>
    </p>
    <p><strong>¿Qué información se  recopila?</strong><br>
      Nosotros recopilamos información de usted cuando usted se registra en nuestro sitio o en nuestra aplicación, se suscribe a un boletín o llena un formulario.<br>
      Al ordenar o registrarse en nuestro sitio/aplicaciones, en su caso, se le puede pedir que introduzca su: nombre, dirección e-mail, localidad en la que reside, fecha de nacimiento y otros datos personales. Usted puede, sin embargo, visitar nuestro sitio de forma anónima.<br>
    </p>
    <p><strong>¿Para qué  utilizamos su información?</strong><br>
      Toda la información que obtenemos de usted puede ser usada en una de las siguientes maneras:</p>
    <p>(a) Para personalizar su experiencia. Su información nos ayuda a responder mejor a sus necesidades individuales<br>
      (b) Para mejorar nuestros servicios. Nos esforzamos continuamente para mejorar su experiencia y brindarle servicios e información más personalizada basadas en la información y la retroalimentación que recibimos de usted.<br>
      (c) Para mejorar el servicio al cliente. Su información nos ayuda a responder más eficazmente a sus solicitudes de servicio al cliente y las necesidades de apoyo.</p>
    <p>(d) Para enviar correos electrónicos periódicos. La dirección de correo electrónico que usted proporciona, se puede utilizar para enviar información y las actualizaciones correspondientes a su solicitud, además de recibir noticias de la compañía ocasional, actualizaciones de productos relacionados o servicio de información, etc.<br>
    </p>
    <p><strong>¿Cómo protegemos  su información?</strong><br>
      Implementamos una variedad de medidas de seguridad para mantener la seguridad de su información personal cuando usted entra, enviar, ni acceder a su información personal.</p>
    <p></p>
    <p><strong>¿Utilizamos  cookies?</strong><br>
      Sí (Las cookies son pequeños archivos que un sitio o su proveedor de servicios transfiere al disco duro del ordenador mediante el navegador Web (si lo permiten) que permite a los sitios o sistemas de proveedores de servicios para reconocer su navegador y captura y recuerde cierta información<br>
      Utilizamos cookies para entender y guardar sus preferencias para futuras visitas.<br>
    </p>
    <p><strong>¿Revelamos alguna  información a terceros?</strong><br>
      Nosotros no vendemos, comerciamos, o transferir a terceros sus datos personales. Esto no incluye los terceros de confianza que nos asisten en la operación de nuestro sitio web y/o aplicaciones, la realización de nuestro negocio, un servicio que, siempre que dichas partes se comprometen a mantener esta información confidencial. También podemos revelar su información cuando creemos que es apropiado para cumplir con la ley, hacer cumplir las políticas de nuestros servicios, o para proteger la nuestra o de otros derechos, propiedad, o seguridad. Sin embargo, información no personal identificable de visitantes puede ser proporcionada a terceros para la comercialización, publicidad, u otros usos.<br>
    </p>
    <p><strong>Enlaces de  terceros</strong><br>
      De vez en cuando, a nuestra discreción, podemos incluir u ofrecer productos o servicios de terceros en nuestro sitio web y aplicaciones. Estos sitios de terceros tienen políticas de privacidad separadas e independientes. Por lo tanto, no tienen ninguna responsabilidad ni obligación alguna por los contenidos y actividades de estos sitios enlazados. Sin embargo, buscamos proteger la integridad de nuestro sitio/aplicaciones y agradecemos cualquier comentario sobre estos sitios.<br>
    </p>
    <p><strong>Childrens Online Privacy Protection Act Cumplimiento</strong><br>
      Estamos en el cumplimiento de los requisitos de COPPA (Childrens Online Privacy Protection Act), no recoge ninguna información de cualquier persona menor de 13 años de edad. Nuestro sitio web, productos y servicios están dirigidos a personas que tienen por lo menos 13 años de edad o más.<br>
    </p>
    <p><strong>Política de  privacidad online sólo</strong><br>
      Esta política de privacidad online se aplica únicamente a la información recogida a través de nuestra página web y aplicaciones móviles y no a la información recopilada en línea.<br>
    </p>
    <p><strong>Su consentimiento</strong><br>
      Al utilizar nuestro sitio y aplicaciones, usted acepta nuestra política de privacidad.</p>
    <p><strong>Cambios a nuestra  Política de Privacidad</strong><br>
      Si decidimos cambiar nuestra política de privacidad, pondremos esos cambios en esta página.</p>
    </div>

  </section><!-- End Hero -->


</template>

<script>

import router from "../router"

export default {
    
}
</script>

<style>

</style>