<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center" style="background-color:white; min-height: 100%;height: 100%;">
    <div class="container" style="pt-0; padding-top: 25px;">  
        <div class="row">
            <div class="col-3">
            </div>
            <div class="col-6">
            <img :src="imagen" class="rounded-circle img-thumbnail" alt="...">
            </div>
            <div class="col-3">
            
            </div>
        </div>

        <div class="row"> 
            <div class="col-sm-12">
                <h1 class="pt-3 text-center" style="color:#4a4a4a">¿Aceptas mi invitación?</h1>
                <h5 class="pt-1 text-center" style="color:#979797">Para aceptar la invitación de <span style="color:black"> {{this.$route.params.userName.replace('@','')}} </span> instala WeekFlash.</h5>
            </div>
        </div>

        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-8">
                <a :href="link"><img src="/assets/img/invitationButton.png" class="img-fluid pt-3" alt="..."></a>
            </div>
            <div class="col-2">
            
            </div>
        </div>
        <div class="row">
            <div class="col-5">
            </div>
            <div class="col-2">
                <a :href="link"><img src="/assets/img/arrow.png" class="img-fluid pt-4" alt="..."></a>
            </div>
            <div class="col-5">
            
            </div>
        </div>
        <div class="row">

            <div class="col-12">
                <h2 class="pt-4 text-center" style="color:#4a4a4a">Te he invitado a WeekFlash, la App para <span style="color:black">compartir planes</span> con amigos.</h2>
            </div>
            
        </div>
    </div>

  </section><!-- End Hero -->


</template>

<script>

import router from "../router"

export default {
    data() {
        return {
            imagen: window.location.origin + "/assets/img/userDefaultProfilePhoto.png",
            //link: "https://weekflash.page.link/?link=https://www.weekflash.com/@" + this.$route.params.userName.replace('@','') + "&isi=1303585786&ibi=com.magiaworks.weekflash&cid=2526514062951598523&_osl=https://weekflash.page.link&_fpb=CKwGEPcCGgVlbi1jYQ==&_cpt=cpit&_iumenbl=1&_iumchkactval=1&_plt=2052&_uit=4588&_cpb=1&_fpb=CKwGEPcCGgVlbi1jYQ==&_cpt=cpit&_iumenbl=1&_iumchkactval=1&_plt=2052&_uit=42972&_cpb=1&_fpb=CKwGEPcCGgVlbi1jYQ==&_cpt=cpit&_iumenbl=1&_iumchkactval=1&_plt=2052&_uit=68241&_cpb=1" 
            link: ""
        }
    },
    async created(){
        console.log(this.$route.params.userName)
        const res = await fetch("https://weekflash-e92e4.firebaseio.com/users/" + this.$route.params.userName.replace('@','') + "/personalInfo.json")
        const dataDB = await res.json()
        if (dataDB)
        {
            this.imagen = dataDB.profilePhoto
            this.link = dataDB.dynamicLink
        }
        else
        {
            router.push("/")
        }
    }
}
</script>

<style>

</style>