<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center" style="background-color:white; min-height: 100%;height: 100%;">
    <div class="container" style="pt-0; padding-top: 25px;">  
       <h1>Terms of use</h1>
    <p> Last updated: Nov 29, 2021.</p>
    <p>These  Terms of Use contain the terms under which WeekFlash and its affiliates provide  their Services to you and describe how the Services may be accessed and used.  Our Services include the use of Apps,  Websites or other platform usage under WeeFlash&rsquo;s name.<br>
You  indicate your agreement to these Terms by clicking or tapping on a button  indicating your acceptance of these Terms, by executing a document that  references them, or by using the Services.<br>
If  you will be using the Services on behalf of an organization, you agree to these  Terms on behalf of that organization and you represent that you have the  authority to do so. In such case, &ldquo;you&rdquo; and &ldquo;your&rdquo; will refer to that  organization.<br>
<strong>1. Privacy</strong><br>
<strong>1.1.  Privacy.</strong> In the course of using the Services, you may submit content to WeekFlash  (including your personal data and the personal data of others) or third parties  may submit content to you through the Services (your &ldquo;<strong>Content</strong>&rdquo;). We know  that by giving us your Content, you are trusting us to treat it appropriately. WeekFlash&rsquo;s <u>Privacy Policy</u>, together with any  Service-specific data use policies, privacy statements and privacy notices  (collectively, &ldquo;<strong>privacy policies</strong>&rdquo;), detail how we treat your Content and  personal data and we agree to adhere to those privacy policies. You in turn  agree that WeekFlash may use and share your Content in accordance with our  privacy policies.<br>
<strong>1.2.  Confidentiality.</strong> WeekFlash will treat your Content as confidential information and only use and  disclose it in accordance with these Terms (including our privacy policies).  However, your Content is not regarded as confidential information if such  Content: (a) is or becomes public (other than through breach of these Terms by WeekFlash);  (b) was lawfully known to WeekFlash before receiving it from you; (c) is  received by WeekFlash from a third party without knowledge of breach of any  obligation owed to you; or (d) was independently developed by WeekFlash without  reference to your Content.<br>
<strong>2. Your Content</strong><br>
<strong>2.1.  You Retain Ownership of Your Content.</strong> You retain ownership of all of your intellectual  property rights in your Content. WeekFlash does not claim ownership over any of  your Content. These Terms do not grant us any licenses or rights to your  Content except for the limited rights needed for us to provide the Services,  and as otherwise described in these Terms.<br>
<strong>2.2.  Limited License to Your Content.</strong> You grant WeekFlash a worldwide,  royalty free license to use, reproduce, distribute, modify, adapt, create  derivative works, make publicly available, and otherwise exploit your Content,  but only for the limited purposes of providing the Services to you and as  otherwise permitted by WeekFlash&rsquo;s privacy policies. This license for such  limited purposes continues even after you stop using our Services, though you  may have the ability to delete your Content in relation to certain Services  such that WeekFlash no longer has access to it. This license also extends to  any trusted third parties we work with to the extent necessary to provide the  Services to you. If you provide WeekFlash with feedback about the Services, we  may use your feedback without any obligation to you. <br>
<strong>2.3.  Copyright Claims (DMCA Notices).</strong> WeekFlash Inc. responds to notices of  alleged copyright infringement in accordance with the U.S. Digital Millennium  Copyright Act (DMCA). If you believe that your work has been exploited in a way  that constitutes copyright infringement, you may <a href="http://help.surveymonkey.com/articles/en_US/kb/Who-is-your-designated-DMCA-agent?">notify  WeekFlash&rsquo;s agent for claims of copyright infringement</a>.<br>
<strong>2.4.  Other IP Claims.</strong> WeekFlash respects the intellectual property rights of others, and we expect  our users to do the same. If you believe a WeekFlash user is infringing upon  your intellectual property rights, you may <a href="https://smforms.wufoo.com/forms/contact-surveymonkey-abuse/">report it  through our online form</a>. Claims of copyright infringement should follow the  DMCA process outlined in these Terms, or any equivalent process available under  local law.<br>
<strong>3. WeekFlash IP</strong><br>
<strong>3.1.  WeekFlash IP.</strong> Neither these Terms nor your use of the Services grants you ownership in the  Services or the content you access through the Services (other than your  Content). These Terms do not grant you any right to use WeekFlash&rsquo;s trademarks  or other brand elements.<br>
<strong>4. User Content</strong><br>
<strong>4.1.  User Content.</strong> The Services display content provided by others that is not owned by WeekFlash.  Such content is the sole responsibility of the entity that makes it available.  Correspondingly, you are responsible for your own Content and you must ensure  that you have all the rights and permissions needed to use that Content in  connection with the Services. WeekFlash is not responsible for any actions you  take with respect to your Content, including sharing it publicly. Please do not  use content from the Services unless you have first obtained the permission of  its owner, or are otherwise authorized by law to do so.<br>
<strong>4.2.  Content Review.</strong> You acknowledge that, in order to ensure compliance with legal obligations, WeekFlash  may be required to review certain content submitted to the Services to  determine whether it is illegal or whether it violates these Terms (such as  when unlawful content is reported to us). We may also modify, prevent access  to, delete, or refuse to display content that we believe violates the law or  these Terms. However, WeekFlash otherwise has no obligation to monitor or  review any content submitted to the Services.<br>
<strong>4.3.  Third Party Resources.</strong> WeekFlash may publish links in its Services to  internet websites maintained by third parties. WeekFlash does not represent  that it has reviewed such third party websites and is not responsible for them  or any content appearing on them. Trademarks displayed in conjunction with the  Services are the property of their respective owners.<br>
<strong>4.4 Activities and/or Events.</strong> WeekFlash may publish information about third party public or private activities and/or events. The user accepts that no information about activities and/or events on any Weekflash products -Website, Apps, Printed, or any other- must be taken as conclusive. The user may or may not use this information, however it is the user obligation to verify or confirm any information related to activities and/or events. WeekFlash is not responsible in any way to the users or to the organizers for any information about activities and/or events. If an organizer of an event finds out that the information displayed on WeekFlash about their activity and/or event is wrong or wants it deleted, the organizer should write to WeekFlash at www.weekflash.com/contact or through the Contact information in our products and WeekFlash will change or delete the information as required, in a few days. All related to activities and/or events, before, during and after them it is of full responsibility of the activities and/or event organizers and not WeekFlash.<br>
<strong>4.4.1 User published A</strong><strong>ctivities and/or Events</strong>. When a user publishes any Activity or Event -public or private- on a WeekFlash product, WeekFlash may or may not make it public, and also may or may not unpublish and/or delete it. The uses accepts to be the responsible for all the information published. The user declares that all the activities and/or events published on any WeekFlash product are full responsibility of the organizer of the activity or event.<br>
  <strong>
    5. Account Management</strong><br>
      <strong>5.1.  Keep Your Password Secure.</strong> If you have been issued an account by WeekFlash in  connection with your use of the Services, you are responsible for safeguarding  your password and any other credentials used to access that account. You, and  not WeekFlash, are responsible for any activity occurring in your account  (other than activity that WeekFlash is directly responsible for which is not  performed in accordance with the Customer&rsquo;s instructions), whether or not you  authorized that activity. If you become aware of any unauthorized access to  your account, you should notify WeekFlash immediately. Accounts may not be  shared and may only be used by one individual per account. <br>
      <strong>5.2.  Keep Your Details Accurate.</strong> WeekFlash occasionally sends notices to the email  address registered with your account. You must keep your email address and,  where applicable, your contact details and all information details associated  with your account current and accurate. Accounts are controlled by the entity  whose email address is registered with the account. <br>
      <strong>5.3.  Remember to Backup.</strong> You are responsible for maintaining, protecting, and making backups of your  Content. To the extent permitted by applicable law, WeekFlash will not be  liable for any failure to store, or for loss or corruption of, your Content. <br>
      <strong>5.4.  Account Inactivity.</strong> WeekFlash may terminate your account and delete any content contained in it if  there is no account activity (such as a log in event) for over 12 months.  However, we will attempt to warn you by email before terminating your account  to provide you with an opportunity to log in to your account so that it remains  active. <br>
      <strong>6. User Requirements</strong><br>
      <strong>6.1.  Legal Status.</strong> If you are an individual, you may only use the Service if you have the power to  form a contract with WeekFlash. None of the Services are intended for use by  individuals less than 13 years old. If you are under 13 years old or do not  have the power to form a contract with WeekFlash, you may not use the Services.  We recommend that parents and guardians directly supervise any use of the  Services by minors. If you are not an individual, you warrant that you are  validly formed and existing under the laws of your jurisdiction of formation  and that you have duly authorized your agent to bind you to these Terms. <br>
      <strong>6.2.  Embargoes.</strong> You may only use the Services if you are not barred under any applicable laws  from doing so. If you are located in a country embargoed by United States or  other applicable law from receiving the Services, or are on the U.S. Department  of Commerce&rsquo;s Denied Persons List or Entity List, or the U.S. Treasury  Department&rsquo;s list of Specially Designated Nationals, you are not permitted to  purchase any paid Services from WeekFlash. <br>
      <strong>7. Acceptable Uses</strong><br>
      <strong>7.1.  Legal Compliance.</strong> You must use the Services in compliance with, and only as permitted by,  applicable law. <br>
      <strong>7.2.  Your Responsibilities.</strong> You are responsible for your conduct, Content, and  communications with others while using the Services. You must comply with the  following requirements when using the Services: <br>
      (a)  You may not misuse our Services by interfering with their normal operation, or  attempting to access them using a method other than through the interfaces and  instructions that we provide.<br>
      (b)  You may not circumvent or attempt to circumvent any limitations that WeekFlash  imposes on your account (such as by opening up a new account to publish content  that we have closed for a Terms violation).<br>
      (c)  Unless authorized by WeekFlash in writing, you may not probe, scan, or test the  vulnerability of any WeekFlash system or network.<br>
      (d)  Unless permitted by applicable law, you may not deny others access to, or  reverse engineer, the Services, or attempt to do so.<br>
      (e)  You may not transmit any viruses, malware, or other types of malicious  software, or links to such software, through the Services.<br>
      (f)  You may not engage in abusive or excessive usage of the Services, which is  usage significantly in excess of average usage patterns that adversely affects  the speed, responsiveness, stability, availability, or functionality of the  Services for other users. WeekFlash will endeavor to notify you of any abusive  or excessive usage to provide you with an opportunity to reduce such usage to a  level acceptable to WeekFlash.<br>
      (g)  You may not use the Services to infringe the intellectual property rights of  others, or to commit an unlawful activity.<br>
      (h)  Unless authorized by WeekFlash in writing, you may not resell or lease the  Services.<br>
      (i)  If your use of the Services requires you to comply with industry-specific  regulations applicable to such use, you will be solely responsible for such  compliance, unless WeekFlash has agreed with you otherwise. You may not use the  Services in a way that would subject WeekFlash to those industry-specific  regulations without obtaining WeekFlash&rsquo;s prior written agreement. For example,  you may not use the Services to collect, protect, or otherwise handle &ldquo;protected  health information&rdquo; (as defined in 45 C.F.R. §160.103 under United States  federal regulations) without entering into a separate business associate  agreement with WeekFlash that permits you to do so.<br>
      <strong>8. Suspension and Termination of  Services</strong><br>
      <strong>8.1.  By You.</strong> You can stop using our services whenever you like. You can also ask for the  Termination of our services and complete removal of your data by contacting us  and soliciting it via our Website: <a href="http://www.weekflash.com/">www.weekflash.com</a> or via the Contact us link in our App. <br>
      <strong>8.2.  By WeekFlash.</strong> WeekFlash may limit, suspend, or stop providing the Services to you if you fail  to comply with these Terms, or if you use the Services in a way that causes  legal liability to us or disrupts others&rsquo; use of the Services. WeekFlash may  also suspend providing the Services to you if we are investigating suspected  misconduct by you. If we suspend or terminate the Services you receive, we will  endeavor to give you advance notice and an opportunity to export a copy of your  Content from that Service. However, there may be time sensitive situations  where WeekFlash may decide that we need to take immediate action without  notice. WeekFlash has no obligation to retain your Content upon termination of  the applicable Service. <br>
      <strong>8.3.  Further Measures.</strong> If WeekFlash stops providing the Services to you because you repeatedly or  egregiously breach these Terms, WeekFlash may take measures to prevent the  further use of the Services by you, including blocking your IP address. <br>
      <strong>9. Changes and Updates</strong><br>
      <strong>9.1.  Changes to Terms.</strong> WeekFlash may change these Terms at any time for a variety of reasons, such as  to reflect changes in applicable law or updates to Services, and to account for  new Services or functionality. Any changes will be posted to the location at  which those terms appear. WeekFlash may also provide notification of changes on  its blog or via email. Changes will be effective no sooner than the day they  are publicly posted. In order for certain changes to become effective,  applicable law may require WeekFlash to obtain your consent to such changes, or  to provide you with sufficient advance notice of them. If you do not want to  agree to any changes made to the terms for a Service, you should stop using  that Service, because by continuing to use the Services you indicate your agreement  to be bound by the updated terms. <br>
      <strong>9.2.  Changes to Services.</strong> WeekFlash constantly changes and improves the Services. WeekFlash may add,  alter, or remove functionality from a Service at any time without prior notice.  WeekFlash may also limit, suspend, or discontinue a Service at its discretion.  If WeekFlash discontinues a Service, we will give you reasonable advance notice  to provide you with an opportunity to export a copy of your Content from that  Service. WeekFlash may remove content from the Services at any time in our sole  discretion, although we will endeavor to notify you before we do that if it  materially impacts you and if practicable under the circumstances. It is your responsibility to keep the latest version of WeekFlash updated in your device to avoid some security issues. <br>
      <strong>10. Disclaimers and Limitations of  Liability</strong><br>
      <strong>10.1.  Disclaimers.</strong> While it is in WeekFlash&rsquo;s interest to provide you with a great experience when  using the Services, there are certain things we do not promise about them. We  try to keep our online Services up, but they may be unavailable from time to  time for various reasons. EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS AND TO  THE EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; AND WEEKFLASH  DOES NOT MAKE WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING  THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND  NON-INFRINGEMENT OR ANY REGARDING AVAILABILITY, RELIABILITY, OR ACCURACY OF THE  SERVICES. <br>
      <strong>10.2.  Exclusion of Certain Liability.</strong> TO THE EXTENT PERMITTED BY APPLICABLE  LAW, WEEKFLASH, ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS, AND  LICENSORS WILL NOT BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL,  INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES WHATSOEVER, INCLUDING DAMAGES FOR  LOST PROFITS, LOSS OF USE, LOSS OF DATA, ARISING OUT OF OR IN CONNECTION WITH  THE SERVICES AND THESE TERMS, AND WHETHER BASED ON CONTRACT, TORT, STRICT  LIABILITY, OR ANY OTHER LEGAL THEORY, EVEN IF WEEKFLASH HAS BEEN ADVISED OF THE  POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL  PURPOSE. <br>
      <strong>10.3.  Limitation of Liability.</strong> TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE  AGGREGATE LIABILITY OF EACH OF WEEKFLASH, ITS AFFILIATES, OFFICERS, EMPLOYEES,  AGENTS, SUPPLIERS, AND LICENSORS ARISING OUT OF OR IN CONNECTION WITH THE  SERVICES AND THESE TERMS WILL NOT EXCEED THE GREATER OF: (A) THE AMOUNTS PAID  BY YOU TO WEEKFLASH FOR USE OF THE SERVICES AT ISSUE DURING THE 3 MONTHS PRIOR  TO THE EVENT GIVING RISE TO THE LIABILITY; AND (B) US$25.00. <br>
      <strong>10.4.  Consumers.</strong> We acknowledge that the laws of certain jurisdictions provide legal rights to  consumers that may not be overridden by contract or waived by those consumers.  If you are such a consumer, nothing in these Terms limits any of those consumer  rights. <br>
      <strong>10.5.  Businesses.</strong> If you are a business, you will indemnify and hold harmless WeekFlash and its  affiliates, officers, agents, and employees from all liabilities, damages, and  costs (including settlement costs and reasonable attorneys&rsquo; fees) arising out  of a third party claim regarding or in connection with your use of the Services  or a breach of these Terms, to the extent that such liabilities, damages and  costs were caused by you. <br>
      <br>
      <strong>11. Other Terms</strong><br>
      <strong>Assignment.</strong> You may not  assign these Terms without WeekFlash&rsquo;s prior written consent, which may be  withheld in WeekFlash&rsquo;s sole discretion. WeekFlash may assign these Terms at  any time without notice to you. <br>
      <strong>Entire  Agreement.</strong> These Terms (including the Additional Terms) constitute the entire agreement  between you and WeekFlash, and they supersede any other prior or  contemporaneous agreements, terms and conditions, written or oral concerning  its subject matter. Any terms and conditions appearing on a purchase order or  similar document issued by you do not apply to the Services, do not override or  form a part of these Terms, and are void. <br>
      <strong>Independent  Contractors.</strong> The relationship between you and WeekFlash is that of independent contractors,  and not legal partners, employees, or agents of each other. <br>
      <strong>Interpretation.</strong> The use of the  terms &ldquo;includes&rdquo;, &ldquo;including&rdquo;, &ldquo;such as&rdquo;, and similar terms, will be deemed not  to limit what else might be included. <br>
      <strong>No  Waiver.</strong> A party&rsquo;s failure or delay to enforce a provision under these Terms is not a  waiver of its right to do so later. <br>
      <strong>Precedence.</strong> To the extent  any conflict exists, the Additional Terms prevail over this Terms Of Use with  respect to the Services to which the Additional Terms apply. <br>
      <strong>Severability.</strong> If any  provision of these Terms is determined to be unenforceable by a court of  competent jurisdiction, that provision will be severed and the remainder of  terms will remain in full effect. </p>
    <strong>Third Party Beneficiaries.</strong> There are no third party beneficiaries to these  Terms.
    </div>

  </section><!-- End Hero -->


</template>

<script>

import router from "../router"

export default {
    
}
</script>

<style>

</style>