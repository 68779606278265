<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" style="background-color:white; min-height: 100%;height: 100%;">
    <div class="container">  

<a :href="link">
        <div class="d-flex justify-content-center">
         
            <div class="d-flex justify-content-start" style="width: 375px; height:375px; background-image:url('https://firebasestorage.googleapis.com/v0/b/weekflash-e92e4.appspot.com/o/favorite%2F-MFuU3oIRs5H_Q7SiamG%2Falbum%2F943837292.jpg?alt=media&token=bbe5a324-c413-45be-82df-3b8d472b0073'); background-position: center; background-size:cover;">
            
                <div class="d-flex justify-content-start  align-items-end">
                    <div class="d-flex align-items-center pl-3" style="height:60px;width:70px;"> <img :src="category" :hidden="hideCategory" class="img" style="width:50px;" alt="..."></div>
                    <div class="d-flex align-items-center pl-2 favName" style="height:60px;width:305px;">{{name}}</div>
                </div>
            </div>
           
        </div>
        <div class="d-flex justify-content-center">
           <a :href="link"><img :src="buttons" class="img-fluid" style="width: 375px;" alt="..."></a>
        </div>
        <div class="d-flex justify-content-center">
            <div class="d-flex justify-content-start pl-2 pt-2 favDesired" :hidden="hideFdCount" style="width: 375px;">Recomendado por {{fdCount}}</div>
        </div>
         <div class="d-flex justify-content-center">
            <div class="d-flex align-items-center pl-1" style="height:40px;width:40px;"> <img :src="userProfilePhotoUrl" :hidden="hideCategory" class="img rounded-circle" style="width:20px;height:20px;" alt="..."></div>
            <div class="d-flex align-items-center favDesired" style="height:40px;width:265px;">{{userFullName}}</div>
            <div class="d-flex align-items-center pl-1" style="height:25px;width:60px; border-style: solid; border-color: black; color: black; border-radius: 5px; border-width: 1px;">Follow</div>
        </div>
         <div class="d-flex justify-content-center pt-2">
            <div class="d-flex align-items-center pl-2" style="height:35px;width:40px;"> <img :src="favLocationIcon" :hidden="hideLocation" class="img" style="width:18px;" alt="..."></div>
            <div class="d-flex align-items-center favDesired" :hidden="hideLocation"  style="height:35px;width:335px;">{{location}}</div>
        </div>
 </a>
        <div class="d-flex justify-content-center" :hidden="hideRow1">
            <div class="d-flex p-2 align-items-center" style="height:125px;width:125px;">  <img :src="photo1" :hidden="hidePhoto1" class="img rounded" style="width:100px;height: 100px; cursor: pointer;" alt="..."  v-on:click="showMultiple(0)"></div>
             <div class="d-flex p-2 align-items-center" style="height:125px;width:125px;">  <img :src="photo2" :hidden="hidePhoto2" class="img rounded" style="width:100px;height: 100px; cursor: pointer;" alt="..." v-on:click="showMultiple(1)"></div>
              <div class="d-flex p-2 align-items-center" style="height:125px;width:125px;">  <img :src="photo3" :hidden="hidePhoto3" class="img rounded" style="width:100px;height: 100px; cursor: pointer;" alt="..." v-on:click="showMultiple(2)"></div>
        </div>

        <div class="d-flex justify-content-center" :hidden="hideRow2">
            <div class="d-flex p-2 align-items-center" style="height:125px;width:125px;">  <img :src="photo4"  :hidden="hidePhoto4" class="img rounded" style="width:100px;height: 100px; cursor: pointer;" alt="..." v-on:click="showMultiple(3)"></div>
             <div class="d-flex p-2 align-items-center" style="height:125px;width:125px;">  <img :src="photo5"  :hidden="hidePhoto5" class="img rounded" style="width:100px;height: 100px; cursor: pointer;" alt="..." v-on:click="showMultiple(4)"></div>
              <div class="d-flex p-2 align-items-center" style="height:125px;width:125px;">  <img :src="photo6"  :hidden="hidePhoto6" class="img rounded" style="width:100px;height: 100px; cursor: pointer;" alt="..." v-on:click="showMultiple(5)"></div>
        </div>

        <div class="d-flex justify-content-center" :hidden="hideRow3">
            <div class="d-flex p-2 align-items-center" style="height:125px;width:125px;">  <img :src="photo7" :hidden="hidePhoto7" class="img rounded" style="width:100px;height: 100px; cursor: pointer;" alt="..." v-on:click="showMultiple(6)"></div>
             <div class="d-flex p-2 align-items-center" style="height:125px;width:125px;">  <img :src="photo8" :hidden="hidePhoto8" class="img rounded" style="width:100px;height: 100px; cursor: pointer;" alt="..." v-on:click="showMultiple(7)"></div>
              <div class="d-flex p-2 align-items-center" style="height:125px;width:125px;">  <img :src="photo9" :hidden="hidePhoto9" class="img rounded" style="width:100px;height: 100px; cursor: pointer;" alt="..." v-on:click="showMultiple(8)"></div>
        </div>
        
        
 

       
</div>
           
 
            <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>

  </section><!-- End Hero -->


</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import router from "../router"

export default {
    components: {
        VueEasyLightbox,
    },
    data() {
        return {
            name: "",
            image: window.location.origin + "/assets/img/defFavorite.png",
            link: "",
            category:"",
            description:"",
            hideCategory:true,
            location:"",
            hideLocation:true,
            favLocationIcon:window.location.origin + "/assets/img/location.png",
            fdCount:0,
            hideFdCount:true,
            userProfilePhotoUrl: window.location.origin + "/assets/img/profilePicEmpty.png",
            userFullName:"",
            buttons: window.location.origin + "/assets/img/buttons.png",
            review: window.location.origin + "/assets/img/opinion.png",
            userReviewText:"",
            imgs: [], 
            hideRow1:true,
            hideRow2:true,
            hideRow3:true,
            hidePhoto1:true,
            hidePhoto2:true,
            hidePhoto3:true,
            hidePhoto4:true,
            hidePhoto5:true,
            hidePhoto6:true,
            hidePhoto7:true,
            hidePhoto8:true,
            hidePhoto9:true,
            photo1:"",
            photo2:"",
            photo3:"",
            photo4:"",
            photo5:"",
            photo6:"",
            photo7:"",
            photo8:"",
            photo9:"",
             
      visible: false,
      index: 0
            
        }
    },
    methods: {
    showSingle() {
     
      this.show()
    },
    showMultiple(index) {
    
      this.index = index // index of imgList
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  },
    async created(){
        console.log(this.$route.params.favoriteId)
        const res = await fetch("https://weekflash-e92e4.firebaseio.com/favorites/" + this.$route.params.favoriteId + ".json")
        const dataDB = await res.json()
        if (dataDB)
        {
            console.log("dataDB : " + JSON.stringify(dataDB))
            console.log("cantidad album : " + JSON.stringify(dataDB.album.length))
            
            if (dataDB.hasOwnProperty("album"))
            {
                this.imgs = dataDB.album

                if (dataDB.album.length > 0) 
                { 
                    this.hideRow1 = false 
                    if (dataDB.album[0]) { this.hidePhoto1 = false; this.photo1 = dataDB.album[0] }
                    if (dataDB.album[1]) { this.hidePhoto2 = false; this.photo2 = dataDB.album[1] }
                    if (dataDB.album[2]) { this.hidePhoto3 = false; this.photo3 = dataDB.album[2] }
                }
                if (dataDB.album.length > 3) 
                { 
                    this.hideRow2 = false   
                    if (dataDB.album[3]) { this.hidePhoto4 = false; this.photo4 = dataDB.album[3] }
                    if (dataDB.album[4]) { this.hidePhoto5 = false; this.photo5 = dataDB.album[4] }
                    if (dataDB.album[5]) { this.hidePhoto6 = false; this.photo6 = dataDB.album[5] }    
                }
                if (dataDB.album.length > 6) 
                { 
                    console.log("hay mas de 6")
                    this.hideRow3 = false
                    if (dataDB.album[6]) { this.hidePhoto7 = false; this.photo7 = dataDB.album[6] }
                    if (dataDB.album[7]) { this.hidePhoto8 = false; this.photo8 = dataDB.album[7] }
                    if (dataDB.album[8]) { this.hidePhoto9 = false; this.photo9 = dataDB.album[8] } 
                }

                if(dataDB.album[0].includes(".mov"))
				{
					const pathFile = dataDB.album[0].split('file%2F');
					if (pathFile.length > 1)
					{
						const noMov = pathFile[1].split('.mov');
						this.image = pathFile[0] + "file%2Fthumbnail.jpg" + noMov[1];
					}
				}
				else
				{
					const pathFile = dataDB.album[0].split('file%2F');
					const pathAlbum = dataDB.album[0].split('album%2F');
			
					if (pathFile.length > 1)
					{
						this.image = pathFile[0] + "file%2Fmedium___" + pathFile[1];
					}
					else if (pathAlbum.length > 1)
					{
						this.image = pathAlbum[0] + "album%2FfavoriteMiddle___" + pathAlbum[1];
					}
				}
            }

            if (dataDB.hasOwnProperty("name"))
            {
                this.name = dataDB.name
            }

            if (dataDB.hasOwnProperty("userName"))
            {
                this.userFullName = dataDB.userName
                const userRes = await fetch("https://weekflash-e92e4.firebaseio.com/feed_v2/" + dataDB.userName + "/personalInfo.json")
                const userDB = await userRes.json()
                if (userDB)
                {
                   console.log("userDB : " + JSON.stringify(userDB))
                    if (userDB.hasOwnProperty("fullName"))
                    {
                        this.userFullName = userDB.fullName
                    } 

                    if (userDB.hasOwnProperty("profilePhoto"))
                    {
                        this.userProfilePhotoUrl = userDB.profilePhoto
                    }

                    this.userReviewText = "Deja tu opinión de " + dataDB.name
                }
                
            }

            if (dataDB.hasOwnProperty("description"))
            {
                this.description = dataDB.description
            }

            let fdCount = 0
            if (dataDB.hasOwnProperty("likes"))
            {
                fdCount += Object.keys(dataDB.likes).length	
            }

            if (dataDB.hasOwnProperty("wishlist"))
            {
                fdCount += Object.keys(dataDB.wishlist).length	
            }
           
            if (fdCount > 0)
            {
                this.fdCount = fdCount
                this.hideFdCount = false
            }

            if (dataDB.hasOwnProperty("location") && dataDB.location.hasOwnProperty("name"))
            {
                this.location = dataDB.location.name 
                this.hideLocation = false 
            }

            if (dataDB.hasOwnProperty("category"))
            {
                switch (dataDB.category)
                {
                     case "food": { 
                        this.category = "/assets/img/favFood.png"
                        break; 
                    } 
                    case "party": { 
                        this.category = "/assets/img/favParty.png"
                        break; 
                    } 
                    case "video": { 
                        this.category = "/assets/img/favMovie.png"
                        break; 
                    } 
                    case "art": { 
                        this.category = "/assets/img/favArt.png"
                        break; 
                    } 
                    case "explore": { 
                        this.category = "/assets/img/favExplore.png"
                        break; 
                    } 
                    case "sport": { 
                        this.category = "/assets/img/favSport.png"
                        break; 
                    } 
                    case "game": { 
                        this.category = "/assets/img/favGame.png"
                        break; 
                    } 
                    default: { 
                        this.category = "/assets/img/favStyle.png"
                        break; 
                    } 
                }
                this.hideCategory = false
            }

            if (dataDB.hasOwnProperty("dynamicLink"))
            {
                this.link = dataDB.dynamicLink
            }
        }
        else
        {
            router.push("/")
        }
    }
}
</script>

<style>
div.favoritePhoto {
  background-color: lightblue;
  width: 375px;
  height: 375px;
  overflow: scroll;
}

.favName {
font-family: Tahoma, Geneva, sans-serif;
font-size: 24px;
letter-spacing: 0px;

color: #FFFFFF;
font-weight: 400;
text-decoration: none;
font-style: normal;
font-variant: normal;
text-transform: none;
}

.favDesired {
font-family: Tahoma, Geneva, sans-serif;
color: black;
font-size: 13px;
letter-spacing: 0.5px;
font-weight: 550;
text-decoration: none;
font-style: normal;
font-variant: normal;
text-transform: none;
}

.gallery {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap
}

</style>